var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-con-loading__container",attrs:{"id":"invoice_table"}},[_c('vs-table',{attrs:{"search":"","data":_vm.invoices,"noDataText":_vm.$t('INVOICE.NO_DATA'),"stripe":"","max-items":"50","pagination":"","hoverFlat":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].bez}},[_vm._v(" "+_vm._s(data[indextr].bez)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].invoice_nr}},[_vm._v(" "+_vm._s(data[indextr].invoice_nr)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].type}},[_vm._v(" "+_vm._s(_vm.GetInvoiceTypeText(data[indextr].type))+" ")]),_c('vs-td',{attrs:{"data":data[indextr].last_change}},[_vm._v(" "+_vm._s(_vm.DateToString(data[indextr].last_change,false))+" ")]),_c('vs-td',{attrs:{"data":data[indextr].state}},[_c('vs-chip',{attrs:{"color":_vm.GetCommonStateColor(data[indextr].state)}},[_vm._v(" "+_vm._s(_vm.GetCommonStateText(data[indextr].state)))])],1),_c('vs-td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invoiceForOrder),expression:"!invoiceForOrder"}],attrs:{"data":data[indextr].created_by_str}},[_vm._v(" "+_vm._s(data[indextr].created_by_str)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.GetInvoiceSumOffen(data[indextr]))+" / "),_c('h4',[_vm._v(_vm._s(_vm.GetInvoiceSum(data[indextr])))])]),_c('vs-td',[_c('div',{staticClass:"d-flex"},[_c('PollingButtonDoc',{ref:"InvoicePollingBtn",refInFor:true,attrs:{"DocumentType":_vm.docType,"InitDocId":data[indextr].document_fk,"LinkedFk":data[indextr].id,"Icon":"get_app","Size":"small","Color":"primary","Type":"filled","ButtonClass":"mr-1"},on:{"clicked":_vm.DownloadInvoice}}),_c('SendDebitorDocuemntMailBtn',{staticClass:"mr-1",attrs:{"doc_nr":data[indextr].invoice_nr,"doc_id":data[indextr].id,"doc_type":_vm.docType}}),_c('ButtonList',{attrs:{"buttonArray":[{label:_vm.$t('INVOICE.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true, disabled:false,data:[data[indextr],indextr]},
                                       {label:_vm.$t('INVOICE.BTN.SHOW'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:data[indextr].document_fk <= 0,data:[data[indextr].document_fk]},
                                       {label:_vm.$t('INVOICE.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show: _vm.$can('delete', 'invoice'),disabled:_vm.isDisabled,data:[data[indextr].id,data[indextr].invoice_nr]},
                                       {label:_vm.$t('INVOICE.BTN.STORNO'),color:'primary',type:'filled',icon:'clear',show:true,disabled:_vm.isDisabled,data:[data[indextr].id,data[indextr].invoice_nr]},
                                       {label:_vm.$t('INVOICE.BTN.PAYMENT'),color:'primary',type:'filled',icon:'euro',show:true,disabled:_vm.paymentNotAllowed(data[indextr]),data:[data[indextr]]}
                                      ]},on:{"BtnClicked":_vm.ButtonListClicked}})],1)]),_c('vs-td',{directives:[{name:"show",rawName:"v-show",value:(_vm.invoiceForOrder),expression:"invoiceForOrder"}]},[_c('vs-button',{directives:[{name:"show",rawName:"v-show",value:(data[indextr].isForeign),expression:"data[indextr].isForeign"}],attrs:{"disabled":_vm.isDisabled,"size":"small","color":"primary","type":"filled","icon":"remove"},on:{"click":function($event){return _vm.RemoveInvoiceFromOrder(data[indextr])}}})],1)],1)})}}])},[_c('template',{slot:"header"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.HEADER'))+" ")])]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"bez"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL1'))+" ")]),_c('vs-th',{attrs:{"sort-key":"invoice_nr"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL2'))+" ")]),_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL7'))+" ")]),_c('vs-th',{attrs:{"sort-key":"last_change"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL4'))+" ")]),_c('vs-th',{attrs:{"sort-key":"state"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL5'))+" ")]),_c('vs-th',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invoiceForOrder),expression:"!invoiceForOrder"}],attrs:{"sort-key":"created_by_str"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL6'))+" ")]),_c('vs-th',{attrs:{"sort-key":"betrag"}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE.TABLE.COL8'))+" ")]),_c('vs-th')],1)],2),_c('AddPaymentDlg',{ref:"addPaymentDlg",on:{"Success":_vm.PaymentAdded}}),_c('vs-popup',{attrs:{"fullscreen":"","title":_vm.$t('INVOICE.POPUP_INVOICE'),"active":_vm.pdfPopupActive},on:{"update:active":function($event){_vm.pdfPopupActive=$event}}},[_c('PdfJsViewer',{ref:"pdfViewer",attrs:{"fileName":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }