<template>
    <div>
      <vs-prompt
      @cancel="Canceld"
      @accept="Accepted"
      @close="Closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddObjectDebitorRoom"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ADD_DEBITOROBJECT_ROOM.HEADER.TEXT')}}</p>
          <vs-divider>{{$t('ADD_DEBITOROBJECT_ROOM.DIVIDER.NAME')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.NAME')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.NAME')"  v-model="name"  :danger="name_fail" val-icon-danger="clear" />                           
          </div>
          <vs-divider>{{$t('ADD_DEBITOROBJECT_ROOM.DIVIDER.MASSE')}}</vs-divider>
          <div class="default-input d-flex align-items-center mb-3">
            <vs-input-number v-model="length" :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.LENGTH')" step="0.5" class="inputx mr-2" :class="length_fail ? 'input_number_invalid':''"/>
            <vs-input-number v-model="width" :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.WIDTH')" step="0.5" class="inputx mr-2" :class="width_fail ? 'input_number_invalid':''"/>
            <vs-input-number v-model="height" :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.HEIGHT')" class="inputx mr-3"  :class="height_fail ? 'input_number_invalid':''"/>
            <span> {{$t('ADD_DEBITOROBJECT_ROOM.LABEL.VOL')}} {{volume}}</span>
            <vs-tooltip :text="$t('ADD_DEBITOROBJECT_ROOM.TOOLTIP.CALC_VOL')">
              <vs-button  size="small" color="primary" type="filled" icon="functions" class="ml-2" @click="CalcVolume()"></vs-button>
            </vs-tooltip>
          </div>
          <vs-divider>{{$t('ADD_DEBITOROBJECT_ROOM.DIVIDER.WNDOWS_WALLS')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.AUSSENW')"  class="float-left mr-2 inputx mb-2" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.AUSSENW')"  v-model="aussenwand"  />
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.DECKE')"  class="float-left mr-2 inputx  mb-2" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.DECKE')"  v-model="decke"  />
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.BODEN')"  class="float-left mr-2 inputx mb-2" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.BODEN')"  v-model="boden"  />
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.FENSTER')"  class="float-left mr-2 inputx mb-2" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.FENSTER')"  v-model="fenster"  />
            <vs-input :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.INNENW')"  class="float-left mr-2 inputx mb-2" :placeholder="$t('ADD_DEBITOROBJECT_ROOM.LABEL.PLACEHOLDER.INNENW')"  v-model="innenwaende"  />
          </div>
          <vs-textarea height="200px" counter="500" :label="$t('ADD_DEBITOROBJECT_ROOM.LABEL.BESCHREIBUNG')" :counter-danger.sync="description_fail" v-model="description" />
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';

export default {
  name: "AddDebitorObjectRoomDlg",
  props:['InitData'],
  data: function (){
     return {
      dlgActive: false,
      popupTitle: this.$t('ADD_DEBITOROBJECT_ROOM.TITLE'),
      id:0,
      name:"",
      description:"",
      length:0, 
      width:0, 
      debitorobject_fk:0,
      height:0,
      volume:0, 
      aussenwand:"", 
      decke:"", 
      boden:"", 
      fenster:"", 
      innenwaende:"",
      description_fail:false,
      name_ok:false,
      name_fail:false,
      length_fail:false,
      length_ok:false,
      width_fail:false,
      width_ok:false,
      height_fail:false,
      height_ok:false,
      volume_fail:false,
      volume_ok:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ShowDlg(id){
        this.ClearForm();
        this.debitorobject_fk = id;
        this.dlgActive = true;
      },
      Closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.popupTitle = this.$t('ADD_DEBITOROBJECT_ROOM.TITLE.EDIT');
          this.id = data.id;
          this.name = data.name;
          this.description = data.description;
          this.width = data.width;
          this.length = data.length;
          this.debitorobject_fk = data.debitorobject_fk;
          this.height = data.height;
          this.volume = data.volume; 
          this.aussenwand = data.aussenwand; 
          this.decke = data.decke; 
          this.boden = data.boden; 
          this.fenster = data.fenster; 
          this.innenwaende = data.innenwaende;
          
          this.dlgActive=true;
        }
      },
      Accepted(){

         var name = this.name;

          var data = {
            id: this.id,
            name: this.name,
            description: this.description,
            length: Number(this.length), 
            width: Number(this.width), 
            debitorobject_fk: this.debitorobject_fk,
            height: Number(this.height),
            volume: Number(this.volume), 
            aussenwand: this.aussenwand, 
            decke: this.decke, 
            boden: this.boden, 
            fenster: this.fenster, 
            innenwaende: this.innenwaende
          };

          this.$store.dispatch('debitorobject/saveDebitorObjectRoom', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_DEBITOROBJECT_ROOM.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('ADD_DEBITOROBJECT_ROOM.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted');
          }, 
          error =>{
              var text = this.$t('ADD_DEBITOROBJECT_ROOM.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      Canceld(){
        this.ClearForm();
      },
      ClearForm(){
       
        this.popupTitle = this.$t('ADD_DEBITOROBJECT_ROOM.TITLE');

        this.id = 0;
        this.name = "";
        this.description = "";
        this.width = 0; 
        this.length = 0;
        this.debitorobject_fk = 0;
        this.height = 0;
        this.volume = 0; 
        this.aussenwand = ""; 
        this.decke = ""; 
        this.boden = ""; 
        this.fenster = ""; 
        this.innenwaende = "";
        this.name_ok = false;
        this.name_fail=false;
        this.length_fail=false;
        this.length_ok=false;
        this.width_fail =false;
        this.width_ok = false;
        this.height_fail = false;
        this.height_ok = false;
        this.volume_fail = false;
        this.volume_ok = false;
      },
      CalcVolume(){

        var vol = Number(this.width)* Number(this.length) * Number(this.height);
        vol = Number(vol).toFixed(2);
        return vol;
      }
  },
  computed:{
    calcedVolume()
    {
      return this.CalcVolume();
    },
    formValid(){
        var valid = this.name_ok && Number(this.length) > 0 && Number(this.width) > 0 && Number(this.height) > 0 && Number(this.volume) > 0;
        return valid;
    }
  },
  watch:{
    calcedVolume(value)
    {
      this.volume = value;
    },
    length(value){
      if(value < 0)
      {
        this.length = 0;
      }

      this.length_fail = value <= 0;
      this.length_ok = !this.length_fail;
    },
    width(value){
      if(value < 0)
      {
        this.width = 0;
      }

      this.width_fail = value <= 0;
      this.width_ok = !this.width_fail;
    },
    height(value){
      if(value < 0)
      {
        this.height = 0;
      }

      this.height_fail = value <= 0;
      this.height_ok = !this.height_fail;
    },
    volume(value){
      if(value < 0)
      {
        this.volume = 0;
      }

      this.volume_fail = value <= 0;
      this.volume_ok = !this.volume_fail;
    },
    name(value)
    {
      this.name_fail = value.length == 0;
      this.name_ok = !this.name_fail;
    }
  }
};
</script>
<style>
div#promptAddObjectDebitorRoom > div.vs-dialog{
  max-width: 800px !important;
}
</style>