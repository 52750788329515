<template>
  <div id="offers_table" class="vs-con-loading__container" >
  <vs-table
    search
    :data="offers"
    :noDataText="$t('OFFERS.NO_DATA')"
    stripe
    max-items="25" 
    pagination
    hoverFlat>

    <template slot="header">
      <h3>
        {{$t('OFFERS.TABLE.HEADER')}}
      </h3>
    </template>
    <template slot="thead">                               
      <vs-th sort-key="bez">
        {{$t('OFFERS.TABLE.COL1')}}
      </vs-th>
      <vs-th sort-key="offerNumber">
        {{$t('OFFERS.TABLE.COL2')}}
      </vs-th>                  
      <vs-th sort-key="valid_until">
        {{$t('OFFERS.TABLE.COL3')}}
      </vs-th>        
      <vs-th sort-key="last_change">
        {{$t('OFFERS.TABLE.COL4')}}
      </vs-th>  
      <vs-th sort-key="state">
        {{$t('OFFERS.TABLE.COL5')}}
      </vs-th>
      <vs-th sort-key="created_by_str">
        {{$t('OFFERS.TABLE.COL6')}}
      </vs-th>                      
      <vs-th></vs-th>                                                                                                                                             
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
        <vs-td :data="data[indextr].bez">
          {{data[indextr].bez}}
        </vs-td>      
        <vs-td :data="data[indextr].offerNumber">
          {{data[indextr].offerNumber}}
        </vs-td>                     
        <vs-td :data="data[indextr].valid_until">
          {{DateToString(data[indextr].valid_until,false)}}
        </vs-td>
        <vs-td :data="data[indextr].last_change">
          {{DateToString(data[indextr].last_change,false)}}
        </vs-td>                   
        <vs-td :data="data[indextr].state">
          <vs-chip :color="GetCommonStateColor(data[indextr].state)">
            <vs-avatar icon="lock" color="black" v-show="data[indextr].lockedByUserGuid.length > 0"/>{{GetCommonStateText(data[indextr].state)}}</vs-chip>
        </vs-td>  
        <vs-td :data="data[indextr].created_by_str">
          {{data[indextr].created_by_str}}
        </vs-td>                   
        <vs-td>
          <div class="d-flex">

            <PollingButtonDoc :DocumentType="docType" :InitDocId="data[indextr].document_fk" :LinkedFk="data[indextr].id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" ref="OfferPollingBtn" v-on:clicked="DownloadOffer"></PollingButtonDoc>
            <SendDebitorDocuemntMailBtn class="mr-1" :doc_nr="data[indextr].offerNumber" :doc_id="data[indextr].id" :doc_type="docType"></SendDebitorDocuemntMailBtn>
            <ButtonList :buttonArray="[{label:$t('OFFERS.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true,disabled:false,data:[data[indextr],indextr]},
                                       {label:$t('OFFERS.BTN.EDIT'),color:'primary',type:'filled',icon:'edit',show:true,disabled:!isEditOfferAllowed(data[indextr].state),data:[data[indextr]]},
                                       {label:$t('OFFERS.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show:true,disabled:!isEditOfferAllowed(data[indextr].state),data:[data[indextr].id,data[indextr].offerNumber]},
                                       {label:$t('OFFERS.BTN.TO_ORDER'),color:'success',type:'filled',icon:'add_shopping_cart',show:true,disabled:!isOrderOfferAllowed(data[indextr].state),data:[data[indextr].id,data[indextr].offerNumber]},
                                      ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
          </div>
        </vs-td>                                                                                                                                                                                          
      </vs-tr>
    </template>
  </vs-table>
  <AddOrderDlg ref="addOrderDlg"  v-on:Success="OrderAdded"></AddOrderDlg>
</div> 
</template>
<script>
import enumHelper from '../../../helper/enumHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import helperMethods from '../../../helper/staticFuncHelper';
import downloadMethods from '../../../helper/staticDownloadFuncHelper';
import {documentType} from "../../../helper/enumHelper";
import PollingButtonDoc from "../PollingButtonDoc.vue";
import AddOrderDlg from '../dialogs/AddOrderDlg.vue';
import ButtonList from '../ButtonList.vue';
import SendDebitorDocuemntMailBtn from '../dialogs/SendDebitorDocuemntMailBtn.vue';

export default {
  name: "OfferTable",
  props:['DebitorFk','DebitorObjectFk'],
  components:
  {
    PollingButtonDoc,
    AddOrderDlg,
    ButtonList,
    SendDebitorDocuemntMailBtn
  },
   data: function (){
     return {
       offers:[],
       docType:documentType.OFFER
     }
  },
  created(){
    this.RefreshData(this.DebitorFk,this.DebitorObjectFk);
  },
  computed:{
    getOfferTableData() {
      var data = [];
      if(this.$store.state.accounting.offers.data != null)
      {
        data = this.$store.state.accounting.offers.data;
      }
      return data;
    },
    loadingOfferTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.offers.status) != 'undefined')
        loading = this.$store.state.accounting.offers.status.loading;
      return loading;
    }
  },
  methods:{ 
    ...codeHelper,
    ...helperMethods,
    ...enumHelper,
    ...downloadMethods,
    RefreshData(deb,obj){
      var dat = {debitor_fk: deb, debitorobj_fk:obj};
      this.$store.dispatch('accounting/getOffers',dat ); 
      // Details zum Debitor laden
      this.$store.dispatch('debitor/getDebitor',deb );  
    },

    ButtonListClicked(index, data){
      switch(index)
      {
        case 0:
          this.PrintOffer(data[0])
          break;
        case 1:
          this.ShowOffer(data[0])
          break;
        case 2:
          this.OpenConfirmDelete(data[0],data[1])
          break;  
        case 3:
          this.CreateOrder(data[0],data[1]);
          break;                           
      }
      
    },
    ShowOffer(data)
    {
      this.$emit('ShowOffer',data,this.isEditOfferAllowed(data.state));
    },
    PrintOffer(data)
    {
       var name = data.bez;
            this.$store.dispatch('document/createDocument', {id:data.id,name:data.offerNumber, type: documentType.OFFER})
              .then(success => {
                if(success === true)
                {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.ERROR.PRINT').replace("*1*",name)});
                }
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
    },
    DownloadOffer(actDocId)
    {
      if(this.IsNotNull(actDocId))
      {
          this.$store.dispatch('document/downloadDocument', actDocId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
      }
    },    
    OpenConfirmDelete(id,bez) {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: this.$t('COM.SURE.TITEL'),
        acceptText: this.$t('COM.DELETE'),
        cancelText:this.$t('COM.CANCEL'),
        text: this.$t('OFFERS.QUESTION.DELETE').replace("*1*",bez),
        accept: this.SendDeleteRequest,
        parameters:{"id": id,"bez": bez}
      });
    
    },
    SendDeleteRequest: function(parameters)
    {
      this.$store.dispatch('accounting/deleteOffer', parameters.id)
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
        }
        else
        {
          var text = this.$t('OFFERS.WARNING.DELETE').replace("*1*",parameters.bez);
          text = text.replace("*2*",this.$t(response.text));
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
      });
    },
    CreateOrder(id,offer_nr)
    {
      this.$refs.addOrderDlg.ShowDlg(offer_nr,id);
    },
    OrderAdded(id){
      this.$router.push({ name: 'Order', params: { order_fk: id } });
    }
  },
  watch:{
    getOfferTableData(value)
    {
      this.offers = value;
    },
    loadingOfferTableData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#offers_table'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#offers_table'});
      }
    }
  }
};
</script>
