<template>
  <div >
    <div class=" parentdiv-dropdown">
      <vs-input :label="activeLabel" v-model="text" icon-after="true" class="inputdropdown"  icon="expand_more" ></vs-input>
      <vs-dropdown >
        <vs-button class="btn-drop" type="border" size="small" icon="expand_more"></vs-button>
        <vs-dropdown-menu>
          <vs-dropdown-item :key="'user'+index" v-for="(item,index) in userdefinedOptions" @click="text = item.text">{{item.text}}</vs-dropdown-item>
          <vs-dropdown-item :key="'static'+index" v-for="(item,index) in options" @click="text = item.text">{{item.text}}</vs-dropdown-item>

          <vs-dropdown-item divider @click="AddEntry()">
            <vs-icon class="" icon="add"></vs-icon>{{$t('COM.ADD')}}
          </vs-dropdown-item>

        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>
<script>
import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';
import {inputSelectType} from '../../helper/enumHelper';

export default {
  name: "InputSelectList",
  props:['value', 'type', 'label'],
  components:{},
   data: function (){
     return {
      text:this.value,
      popupActive:false,
      options:[],
      userdefinedOptions:[]
     }
  },
  mounted(){
    this.$store.dispatch('userdefinedOptions/getUserdefinedOptions', this.type);
    switch(this.type)
    {
      case inputSelectType.DEBITOROBJECTSTRUCTURE:
        {
          this.options = this.GetDebitorObjectStructureForSelect();
          break;
        }
      case inputSelectType.DEBITOROBJECTTYPE:
        {
          this.options = this.GetDebitorObjectTypeForSelect();
          break;
        }
      case inputSelectType.DEBITOROBJECTNEEDS:
        {
          this.options = this.GetDebitorObjectNeedsForSelect();
          break;
        }   
      case inputSelectType.DEBITOROBJECTPROBLEMS:
        {
          this.options = this.GetDebitorObjectProblemsForSelect();
          break;
        }             
    }
    
  },
  methods:{
    ...helperMethods,
    ...enumHelper,
    ...codeHelper,

    AddEntry(){

      var data = {type:this.type,text:this.text};
      var name = this.text;

      this.$store.dispatch('userdefinedOptions/insertUserdefinedOption', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COM.ADD.SUCCESS').replace("*1*",name)});  
                this.$store.dispatch('userdefinedOptions/getUserdefinedOptions', this.type);
            }
            else
            {
              var text = this.$t('COM.ADD.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('COM.ADD.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
    }
  },
  computed:{
    activeLabel(){
      var retval = '';
      if(this.IsNotNull(this.label))
      {
        retval = this.label;
      }
      return retval;
    },
    userdefinedOptionsLoaded(){
      var retVal = [];
      if(this.IsNotNull(this.$store.state.userdefinedOptions.options.data))
      {
        var data = this.$store.state.userdefinedOptions.options.data;
        for(var i = 0; i < data.length; i++)
        {
          if(data[i].type == this.type)
          {
            data[i].options.forEach(element => {
              retVal.push(element);
            });
            break;
          }
        }
      }
      return retVal;
    }
  },
  watch:{
    userdefinedOptionsLoaded(value){
      this.userdefinedOptions = value;
    },
    text(value)
    {
      this.$emit('input', value);
    }
  }
};
</script>
<style>
button.btn-drop {
  border-color: rgba(0, 0, 0, 0.2) !important;
  border-width: 0px 0px 0px 1px !important;
  border-radius: 0 !important;
  visibility: hidden;
}
div.parentdiv-dropdown {
  position:relative;
}

.parentdiv-dropdown > button{
 position: absolute;
  border-radius: 0;
  top:30px;
  right:0px;
  z-index:9000;
}
</style>
