<template>
<div>

  <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card>
        <h3 class="card-title d-flex">
          {{$t('DEBITORCONTACT.CARD.DEBITORCONTACT.HEADER')}}
          <div class="ml-auto text-right">
            <div class="d-flex">
              
              <div class="hiddenDesktop ">

                <ButtonList :buttonArray="[{label:$t('DEBITORCONTACT.NEW_OFFER'),color:'primary',type:'filled',icon:'add',show:true, disabled:false,data:[selectedDebitorId,selectedObjectId,selectedDebitorCountry]},
                                       {label:$t('DEBITORCONTACT.NEW_PAYMENT'),color:'primary',type:'filled',icon:'euro',show:true,disabled:false,data:[]}
                                      ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
              </div>
              <vs-chip v-show="isSelectedDebitorContactInfoworx" color="blue">infoworx</vs-chip>
              <vs-button size="small" class="ml-2"  type="filled" icon="print" @click="CheckCreatePdf()" ></vs-button>
              <vs-button size="small" class="ml-2"  type="filled" icon="close" @click="BackBtnClicked()" ></vs-button>
            </div>
          </div>
        </h3>


        <div class="d-flex align-items-center">  
          <vs-button size="small" :disabled="selectedContactPrevId == -1"  type="filled" icon="arrow_back_ios" @click="PrevBtnClicked()" >{{$t('COM.PREV_ENTRY')}}</vs-button>
          <p class="entryCounter">{{selectedContactPos}}&nbsp;/&nbsp;{{selectedContactMax}}</p>
          <vs-button class="small mr-3" :disabled="selectedContactNextId == -1"  type="filled" icon="arrow_forward_ios" @click="NextBtnClicked()" >{{$t('COM.NEXT_ENTRY')}}</vs-button>

          <vs-button class="small ml-3 hiddenMobile"  type="filled" icon="add" @click="$refs.addOfferDlg.ShowDlg(selectedDebitorId,selectedObjectId,selectedDebitorCountry)">{{$t('COM.NEW_OFFER')}}</vs-button>
          <vs-button class="small ml-2 hiddenMobile"  type="filled" icon="euro" @click="$refs.addPaymentDlg.ShowDlg()">{{$t('COM.NEW_PAYMENT')}}</vs-button>
        </div>

        <AddOfferDlg ref="addOfferDlg" v-on:Success="OfferAdded"></AddOfferDlg>
        <AddPaymentDlg ref="addPaymentDlg" v-on:Success="PaymentAdded"></AddPaymentDlg>
        <InfoworxInvoiceDetailDlg ref="infoworxInvoiceDetailDlg" ></InfoworxInvoiceDetailDlg>
        
        <vs-prompt
        @accept="CreatePdf"
        :accept-text="$t('COM.OK')"
        :cancel-text="$t('COM.CANCEL')"
        :title="$t('COM.PRINTOPTIONS')"
        :active.sync="showCheckboxPrompt">
        <div class="d-flex">
          <vs-checkbox v-model="printAttachments">{{$t('DEBITORCONTACT.PRINT_ATTACHMENTS')}}</vs-checkbox>
          <vs-checkbox v-model="printInternal">{{$t('DEBITORCONTACT.PRINT_INTERNAL')}}</vs-checkbox>
        </div>
      </vs-prompt>


      </vs-card>
    </vs-col>
  </vs-row>
  <vs-row vs-justify="left">
    <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card>
        <h3 class="card-title d-flex">
          {{$t('DEBITORCONTACT.CARD.DEB_OBJ.HEADER')}}
          <div class="ml-auto text-right">
            <vs-button @click="$refs.addDebitorDlg.InitDlgData(selectedDebitor)" size="small" color="primary" type="filled" icon="edit"></vs-button>
          </div>
        </h3>
        <vs-list>
          <vs-list-header :title="selectedDebitorName"></vs-list-header>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.DEB_OBJ.TEL')" :subtitle="selectedDebitorTel"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.DEB_OBJ.EMAIL')" :subtitle="selectedDebitorEmail"></vs-list-item>
          <vs-list-header :title="selectedObjectName"></vs-list-header>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.DEB_OBJ.ADR1')" :subtitle="selectedObjectAdrLine1"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.DEB_OBJ.ADR2')" :subtitle="selectedObjectAdrLine2"></vs-list-item>
        </vs-list> 
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="left" vs-align="left" vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card>
        <h3 class="card-title d-flex">
          {{$t('DEBITORCONTACT.CARD.OBJ_DET.HEADER')}}
          <div class="ml-auto text-right">
            <vs-button @click="$refs.addDebitorObjectDlg.InitDlgData(selectedDebitorObject)" size="small" color="primary" type="filled" icon="edit"></vs-button>
          </div>
        </h3>
        <vs-list>
          <vs-list-item :title="selectedObjectDetailLine1.header" :subtitle="selectedObjectDetailLine1.line"></vs-list-item>
          <vs-list-item :title="selectedObjectDetailLine2.header" :subtitle="selectedObjectDetailLine2.line"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.OBJ_DET.NOTE')" :subtitle="selectedObjectDetailProbNote"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.OBJ_DET.EXISTING')" :subtitle="selectedObjectDetailLine3"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.OBJ_DET.HAUPT')" :subtitle="selectedObjectDetailLine4"></vs-list-item>
          <vs-list-item :title="$t('DEBITORCONTACT.CARD.OBJ_DET.DESCR')" :subtitle="selectedObjectDetailBeschr"></vs-list-item>
        </vs-list> 
      </vs-card>
    </vs-col>    
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6"  vs-xs="12">
      <vs-card>

        <AddDebitorObjectRoomDlg ref="addDebitorObjectRoomDlg" v-on:Accepted="RoomAccepted()"></AddDebitorObjectRoomDlg>

        <h3 class="card-title d-flex">
          {{$t('DEBITORCONTACT.CARD.ROOMS.HEADER')}}
          <div class="ml-auto text-right btn-alignment">
            <ButtonList :buttonArray="GetButtonsForRooms()" v-on:BtnClicked="ButtonListClickedRooms" ref="BtnListRooms"></ButtonList>
          </div>
        </h3>
          <div v-if="rooms.length > 1">
          <vs-tabs v-model="activeRoom">
            <template v-for="(room,index) in rooms" > 
            <vs-tab :key="'room_'+index" :label="room.name"> 
              <RoomList :Room="room"></RoomList>
            </vs-tab>
            </template>
          </vs-tabs>
          </div>
          <div v-if="rooms.length == 1">
            <RoomList :Room="rooms[0]"></RoomList>
          </div>
          <div v-if="rooms.length == 0">
            <p>{{$t('DEBITORCONTACT.CARD.ROOMS.NO_DATA')}}</p>
          </div>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="3"  vs-sm="6" vs-xs="12">
      <vs-card>
        <h3 class="card-title d-flex">
          {{$t('DEBITORCONTACT.CARD.ATTACHTMENTS')}}
        </h3>

        <ObjectAttachments :ObjectId="this.selectedObjectId" v-on:ShowPdf="ViewAttachmentPdf"></ObjectAttachments>
        <vs-popup fullscreen :title="$t('DEBITORCONTACT.POPUP_ATTACHMENT')" :active.sync="pdfPopupActive">
            <PdfJsViewer ref="pdfViewer" fileName=""></PdfJsViewer>
        </vs-popup>
        
        <vs-prompt
        @cancel="canceldAttachment"
        @accept="acceptedAttachment"
        @close="closedAttachment"
        :buttons-hidden="true"
        :active.sync="dlgActiveAttachment"
        :title="$t('DEBITORCONTACT.ADD_ATTACHTMENTS')"
        :accept-text="$t('COM.OK')"
        :cancel-text="$t('COM.CANCEL')"
        id="promptAddObjectAttachmentDlg">
          <div>
            <div>
              <div class="clearfix">
                <div class="float-left p-3" id="oldDeb">
                  <vue-dropzone id="attachDrop" ref="attachDrop" 
                    :options="dropOptions" 
                    class="" 
                    v-on:vdropzone-success="dropzoneSuccess" 
                    v-on:vdropzone-error="dropzoneError" 
                    v-on:vdropzone-sending="dropzoneSending"
                    v-on:vdropzone-queue-complete="dropzoneQueueComplete"
                    v-on:vdropzone-file-added ="dropzoneFileAdded">
                  </vue-dropzone>
                </div>
                <div class="float-left p-3"> 
                  <h5 class="mb-2">{{$t('DEBITORCONTACT.ATTACHMENT_DESCR')}}</h5>
                  <vs-button type="filled"  @click="StartUpload">{{$t('DEBITORCONTACT.START_UPLOAD')}}</vs-button>
                  <template v-for="(file,idx) in queuedFiles">
                    <div :key="'file_'+idx"  class="">
                      <vs-input  class="inputx" :label="file.filename" :placeholder="$t('DEBITORCONTACT.EDIT.ATTACH.DESCR')" v-model="file.description" />   
                    </div>
                  </template>
                </div>
                
              </div>
              
            </div>
          </div>
        </vs-prompt>
        <div slot="footer">
        <vs-row vs-justify="flex-end">
          <vs-button @click="ShowAddAttachmentDlg()">{{$t('DEBITORCONTACT.ADD_ATTACHMENT')}}</vs-button>
        </vs-row>
        </div>
      </vs-card>
    </vs-col>    
  </vs-row>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
        
        <AddDebitorContactEventDlg ref="addDebitorContactEventDlg" v-on:Accepted="MemberAccepted()"></AddDebitorContactEventDlg>
        <vs-popup class="holamundo"  :title="$t('DEBITORCONTACT.HISTORY.POPUP_TITLE')" :active.sync="popupEventDescrActive">
          <p>{{actEventDescr}}</p>
        </vs-popup>

        <vs-tabs v-model="activeTab">
          <vs-tab :label="$t('DEBITORCONTACT.HISTORY')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="$refs.addDebitorContactEventDlg.ShowDlg(selectedDebitorContactId)">{{$t('DEBITORCONTACT.ADD_HISTORY_ENTRY')}}</vs-button>
            </div> 
            <div id="debitorcontacts_history_table" class="vs-con-loading__container">
              <vs-table
                search
                :data="selectedContactMember"
                :noDataText="$t('DEBITORCONTACT.HISTORY.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="bez">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="info_short">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="contact_at">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="contacttype">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="reason">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL5')}}
                  </vs-th>  
                  <vs-th sort-key="reason">
                    {{$t('DEBITORCONTACT.HISTORY.TABLE.COL6')}}
                  </vs-th>                     
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="data[indextr].bez">
                      {{data[indextr].bez}}
                    </vs-td>  
                    <vs-td :data="data[indextr].info_short">
                      {{data[indextr].info_short}}
                    </vs-td> 
                    <vs-td :data="data[indextr].contact_at">
                      {{DateToString(data[indextr].contact_at,false)}}
                    </vs-td>                    
                    <vs-td :data="data[indextr].contacttype">
                      <span v-show="data[indextr].isInternal == false">{{GetContactMemberTypeText(data[indextr].contacttype)}}</span>
                    </vs-td>   
                    <vs-td :data="data[indextr].reason">
                      <span v-show="data[indextr].isInternal == false" >{{GetContactMemberReasonText(data[indextr].reason)}}</span>
                    </vs-td>    
                    <vs-td :data="data[indextr].info_full">
                      <vs-button v-show="data[indextr].isInternal == false" size="small" color="primary" type="filled" icon="launch" @click="showEventDescription(data[indextr].info_full)"></vs-button>
                      <vs-button v-show="data[indextr].isInternal == true && data[indextr].state == states.CREATED" size="small" color="primary" type="filled" icon="launch" @click="ShowOfferById(data[indextr].internal_fk)"></vs-button>
                    </vs-td>                                          
                    <vs-td>
                      <div class="d-flex">
                        <vs-button v-show="data[indextr].isInternal == false" size="small" color="primary" type="filled" icon="edit" @click=" $refs.addDebitorContactEventDlg.InitDlgData(data[indextr])"></vs-button>   
                        <vs-button v-show="data[indextr].isInternal == false" class="ml-2" size="small" color="primary" type="filled" icon="delete" @click="OpenConfirmDeleteEvent(data[indextr].id,data[indextr].bez)"></vs-button>                    
                      </div>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>     
          </vs-tab>
          <vs-tab :label="$t('DEBITORCONTACT.OFFERS')" >
            <OfferTable ref="offerTable" :DebitorFk="selectedDebitorId" :DebitorObjectFk="selectedObjectId" v-on:ShowOffer="ShowOffer"></OfferTable>
          </vs-tab>
          <vs-tab :label="$t('DEBITORCONTACT.ORDERS')" >
            <OrderTable ref="orderTable" :DebitorContactFk="selectedDebitorContactId"  v-on:ShowOrder="ShowOrder"></OrderTable>
          </vs-tab>
          <vs-tab :label="$t('DEBITORCONTACT.INVOICES')" >
            <InvoiceTable ref="invoiceTable" :InvoiceForOrder="false" v-model="selectedDebitorContactId"></InvoiceTable>
          </vs-tab>
          <vs-tab :disabled="!isSelectedDebitorContactInfoworx" :label="$t('DEBITORCONTACT.INFOWORX_KORR')" >
            <div id="debitorcontacts_info_korr" class="vs-con-loading__container">
              <vs-table
                search
                :data="selectedContactInfoworxKorr"
                :noDataText="$t('DEBITORCONTACT.INFOWORX_KORR.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORCONTACT.INFOWORX_KORR.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="datum">
                    {{$t('DEBITORCONTACT.INFOWORX_KORR.TABLE.COL1')}}
                  </vs-th> 
                  <vs-th sort-key="betreff">
                    {{$t('DEBITORCONTACT.INFOWORX_KORR.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="text">
                    {{$t('DEBITORCONTACT.INFOWORX_KORR.TABLE.COL3')}}
                  </vs-th>                                                                                                                                                                               
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="data[indextr].datum">
                      {{DateToString(data[indextr].datum,false)}}
                    </vs-td>
                    <vs-td :data="data[indextr].betreff">
                      {{data[indextr].betreff}}
                    </vs-td>   
                    <vs-td :data="data[indextr].text">
                      {{data[indextr].text}}
                    </vs-td>                                                                                                                                                                                                                               
                  </vs-tr>
                </template>
              </vs-table>
            </div>     
          </vs-tab>
          <vs-tab  :disabled="!isSelectedDebitorContactInfoworx" :label="$t('DEBITORCONTACT.INFOWORX_INVOICE')" >
            <div id="debitorcontacts_info_invoice" class="vs-con-loading__container">

              <vs-table
                search
                :data="selectedContactInfoworxInvoices"
                :noDataText="$t('DEBITORCONTACT.INFOWORX_INVOICE.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="invoice_date">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE.TABLE.COL1')}}
                  </vs-th> 
                  <vs-th sort-key="invoice_nr">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="brutto_sum">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="freetext">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE.TABLE.COL4')}}
                  </vs-th> 
                  <vs-th>
                  </vs-th>                                                                                                                                                                                               
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="data[indextr].invoice_date">
                      {{DateToString(data[indextr].invoice_date,false)}}
                    </vs-td>
                    <vs-td :data="data[indextr].invoice_nr">
                      {{data[indextr].invoice_nr}}
                    </vs-td>   
                    <vs-td :data="data[indextr].brutto_sum">
                      {{GetCurrencyString(data[indextr].brutto_sum,data[indextr].language_fk,data[indextr].currency_kuerzel)}}
                    </vs-td>   
                    <vs-td :data="data[indextr].freetext">
                      {{data[indextr].freetext}}
                    </vs-td>
                    <vs-td>
                      <vs-button class="ml-2" size="small" color="primary" type="filled" icon="open_in_new" @click="$refs.infoworxInvoiceDetailDlg.InitDlgData(data[indextr].member)" ></vs-button>                      
                    </vs-td>                                                                                                                                                                                                                               
                  </vs-tr>
                </template>
              </vs-table>
              
            </div>                 
          </vs-tab>                        
        </vs-tabs>      
      </vs-card>
    </vs-col>
  </vs-row>
  <AddDebitorDlg ref="addDebitorDlg" v-on:Accepted="AddDebitorAccepted"></AddDebitorDlg>
  <AddDebitorObjectDlg ref="addDebitorObjectDlg" v-on:Accepted="AddDebitorObjectAccepted" ></AddDebitorObjectDlg>
</div>
</template>
<script>

import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import helperMethods from '../helper/staticFuncHelper';
import AddDebitorObjectRoomDlg from './components/dialogs/AddDebitorObjectRoomDlg';
import RoomList from './components/RoomList';
import AddDebitorContactEventDlg from './components/dialogs/AddDebitorContactEventDlg';
import AddOfferDlg from './components/dialogs/AddOfferDlg';
import OfferTable from './components/tables/OfferTable';
import OrderTable from './components/tables/OrderTable.vue';
import InvoiceTable from './components/tables/InvoiceTable.vue';
import {commonStates} from '../helper/enumHelper';
import AddPaymentDlg from './components/dialogs/AddPaymentDlg.vue';
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../services/localstorage.service';
import ObjectAttachments from './components/ObjectAttachments.vue';
import PdfJsViewer from './components/PdfJsViewer.vue';
import ButtonList from './components/ButtonList.vue';
import {documentType} from '../helper/enumHelper';
import AddDebitorDlg from './components/dialogs/AddDebitorDlg';
import AddDebitorObjectDlg from './components/dialogs/AddDebitorObjectDlg';
import InfoworxInvoiceDetailDlg from './components/dialogs/InfoworxInvoiceDetailDlg';

const localStorageService = LocalStorageService.getService();

export default {
  name: "DebitorContact",
    components: {
    AddDebitorObjectRoomDlg,
    RoomList,
    AddDebitorContactEventDlg,
    AddOfferDlg,
    OfferTable,
    OrderTable,
    InvoiceTable,
    AddPaymentDlg,
    vueDropzone,
    ObjectAttachments,
    PdfJsViewer,
    ButtonList,
    AddDebitorDlg,
    AddDebitorObjectDlg,
    InfoworxInvoiceDetailDlg
  },
  props:[ ],
     data: function (){
     return {
        debitorcontact_fk:0,
        prevBtnDisabled:false,
        nextBtnDisabled:false,
        rooms:[],
        activeRoom:0,
        actEventDescr:"",
        popupEventDescrActive: false,
        activeTab:0,
        states: 0,
        selectedInfoworxInvoice:null,
        prevRoute:null,
        dlgActiveAttachment:false,
        queuedFiles:[],
        pdfPopupActive:false,
        printAttachments:false,
        printInternal:false,
        showCheckboxPrompt:false,
        dropOptions: {
          url: this.$appConfig.apiBaseUrl+'/debitorobjects/attachment/upload',
          maxFilesize: 15, // MB
          maxFiles: 5,
          chunking: false,
          chunkSize: 500, // Bytes
          thumbnailWidth: 100, // px
          thumbnailHeight: 100,
          addRemoveLinks: true,
          headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
          acceptedFiles:".jpg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx",
          forceFallback:false,
          dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
          dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
          dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
          dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
          dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
          dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
          dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
          dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
          dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
          dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
          dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
          dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES'),
          autoProcessQueue:false
        },
     }
  },
  mounted(){
      this.states = commonStates;
      this.debitorcontact_fk = this.$route.params.debitorcontact_fk;
  },
  created(){
  },
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.prevRoute = from;
  })
},
  computed:{
    selectedContact(){
      var data = {};
      if(this.IsNotNull(this.$store.state.debitorcontact.debitorcontact.data))
      {
        data = this.$store.state.debitorcontact.debitorcontact.data;
      }
      return data;
    },
    selectedContactPos(){
      var retVal = 0;
      if(this.IsNotNull(this.selectedContact))
      {
        retVal = this.selectedContact.nav_pos;
      }
      return retVal;
    },
    selectedContactMax(){
      var retVal = 0;
      if(this.IsNotNull(this.selectedContact))
      {
        retVal = this.selectedContact.nav_max;
      }
      return retVal;
    },
    selectedContactNextId(){
      var retVal = -1;
      if(this.IsNotNull(this.selectedContact))
      {
        retVal = this.selectedContact.next_fk;
      }
      return retVal;
    },
    selectedContactPrevId(){
      var retVal = -1;
      if(this.IsNotNull(this.selectedContact))
      {
        retVal = this.selectedContact.prev_fk;
      }
      return retVal;
    },
    selectedContactMember(){
      var retVal = [];
      if(this.IsNotNull(this.selectedContact.debitorcontact))
      {
        retVal = this.selectedContact.debitorcontact.member;
      }
      return retVal;
    },
    selectedContactInfoworxKorr(){
      var retVal = [];
      if(this.IsNotNull(this.selectedContact.debitorcontact))
      {
        retVal = this.selectedContact.debitorcontact.infoworxkorr;
      }
      return retVal;
    }, 
    selectedContactInfoworxInvoices(){
      var retVal = [];
      if(this.IsNotNull(this.selectedContact.debitorcontact))
      {
        retVal = this.selectedContact.debitorcontact.infoworxinvoices;
      }
      return retVal;
    },    
    selectedDebitorContactId()
    {
      var retVal = 0;
      if(this.IsNotNull(this.selectedContact.debitorcontact))
      {
        retVal = this.selectedContact.debitorcontact.id;
      }
      return retVal;
    },
    isSelectedDebitorContactInfoworx()
    {
      var retVal = false;
      if(this.IsNotNull(this.selectedContact.debitorcontact))
      {
        retVal = this.selectedContact.debitorcontact.fromInfoworx;
      }
      return retVal;
    },    
    selectedDebitorName()
    {
      var retVal = "";
      if(this.IsNotNull(this.selectedContact.debitor))
      {
        retVal = this.GetNameOrCompanyFromDebitor(this.selectedContact.debitor.debitor);
      }
      return retVal;
    },
    selectedDebitorTel()
    {
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitor))
      {
        retVal = this.selectedContact.debitor.debitor.tel1;
      }
      return retVal;
    },
    selectedDebitorCountry()
    {
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitor))
      {
        retVal = this.selectedContact.debitor.rech_adr.country_fk;
      }
      return retVal;
    },
    selectedDebitorEmail()
    {
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitor))
      {
        retVal = this.selectedContact.debitor.debitor.email;
      }
      return retVal;
    },
    selectedDebitorObject(){
      return this.selectedContact.debitorobject;
    },
    selectedDebitor(){
      return this.selectedContact.debitor;
    },
    selectedObjectName(){
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        retVal = this.selectedContact.debitorobject.debitorobject.name;
      }
      return retVal;
    },
    selectedObjectAdrLine1()
    {
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var adr = this.selectedContact.debitorobject.address1;
        retVal = adr.street1 + " " + adr.street2;
      }
      return retVal;
    },
    selectedObjectAdrLine2()
    {
      var retVal = "";

      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var adr = this.selectedContact.debitorobject.address1;
        retVal = adr.plz + " " + adr.city + ", " + this.GetNameOfCountry(adr.country_fk);
      }
      return retVal;
    },
    selectedObjectDetailLine1(){
      var retVal = {line: "", header: ""};

      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        
        var texts = [];
        texts.push(obj.objecttype_txt);
        texts.push(obj.objectstructure_txt);
        if(Number(obj.baujahr) == 0)
        {
          texts.push("");
        }
        else
        {
          texts.push(obj.baujahr);
        }

        var header = [];
        header.push(this.$t('DEBITORCONTACT.CARD.OBJ_DET.OBJ_TYPE'));
        header.push(this.$t('DEBITORCONTACT.CARD.OBJ_DET.STRUCTURE'));
        header.push(this.$t('DEBITORCONTACT.CARD.OBJ_DET.YEAR'));

        retVal = this.GetLinkedTextsFromArray(texts,header);
      }
      return retVal;
    },
    selectedObjectDetailLine2(){
      var retVal = {line: "", header: ""};
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var texts = [];
        
        var obj = this.selectedContact.debitorobject.debitorobject;
        texts.push(obj.objectneeds_txt);
        texts.push(obj.objectproblems_txt);
        
        var header = [];
        header.push(this.$t('DEBITORCONTACT.CARD.OBJ_DET.REASON'));
        header.push(this.$t('DEBITORCONTACT.CARD.OBJ_DET.PROBLEM'));
        
        retVal = this.GetLinkedTextsFromArray(texts,header);
      }
      return retVal;
    },
    selectedObjectDetailLine3(){
      var retVal = "";
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        retVal = obj.existing_heating_system;
      }

      if(this.IsNotNull(retVal) == false || retVal.length == 0)
      {
        retVal = this.$t('DEBITORCONTACT.CARD.OBJ_DET.NO_EXIST');
      }
      return retVal;
    },
    selectedObjectDetailLine4(){
      var retVal = "";
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        retVal = obj.alsHauptheizung == true ? this.$t('COM.YES'):this.$t('COM.NO');
      }
      return retVal;
    },
    selectedObjectDetailBeschr()
    {
      var retVal = "";
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        retVal = obj.description;
      }

      if(this.IsNotNull(retVal) == false || retVal.length == 0)
      {
        retVal = this.$t('DEBITORCONTACT.CARD.OBJ_DET.NO_DESCR');
      }
      return retVal;
    },
    selectedObjectDetailProbNote()
    {
      var retVal = "";
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        retVal = obj.objectproblems_note;
      }
      if(this.IsNotNull(retVal) == false || retVal.length == 0)
      {
        retVal = this.$t('DEBITORCONTACT.CARD.OBJ_DET.NO_NOTE');
      }
      return retVal;
    },
    selectedObjectId()
    {
      var retVal = 0;
      if(this.IsNotNull(this.selectedContact.debitorobject))
      {
        var obj = this.selectedContact.debitorobject.debitorobject;
        retVal = obj.id;
      }
      return retVal;
    },
    selectedDebitorId()
    {
      var retVal = 0;
      if(this.IsNotNull(this.selectedContact.debitor))
      {
        retVal = this.selectedContact.debitor.debitor.id;
      }
      return retVal;
    },
    selectedRooms(){
       var data = [];
        if(this.$store.state.debitorobject.rooms.data != null)
        {
          data = this.$store.state.debitorobject.rooms.data;
        }
      return data;
    },
    filesForUpload(){
      return this.$refs.attachDrop.getQueuedFiles();
    },
    numberOfAttachments(){
      var nr = 0;
      if(this.$store.state.debitorobject.attachments.data != null)
      {
        nr = this.$store.state.debitorobject.attachments.data.length;
      }
      
      return nr;
    }
  },
  watch:{
    selectedRooms(value)
    {
      this.rooms = value;
      this.RefreshButtonsForRooms();
    },
    selectedContact(value)
    {
      if(this.IsNotNull(value) && !this.IsEmpty(value))
      {
        // Angebote und Aufträge für diesen Kontakt laden
        var dat = {debitor_fk: value.debitor.debitor.id, debitorobj_fk:value.debitorobject.debitorobject.id};
        this.$store.dispatch('accounting/getOffers',dat ); 
        this.$store.dispatch('debitor/getDebitor',value.debitor.debitor.id );
        
        dat = {debitorcontact_fk: value.debitorcontact.id};
        this.$store.dispatch('accounting/getOrders',dat ); 
        this.LoadRooms();
        this.LoadAttachments();
      }
    },
    debitorcontact_fk(value,old)
    {
      this.LoadData(value,old);
    },
    $route(value)
    {
      this.debitorcontact_fk = value.params.debitorcontact_fk;
    }
  },
  methods:{
    ...enumHelper,
    ...codeHelper,
    ...helperMethods,
    LoadData(id,old){
      
      this.$store.dispatch('debitorcontact/getDebitorContact',id)
      .then(response => {
        
        if(this.IsNotNull(response))
        {
         this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.SUCCESS.LOAD')}); 
        }
      }, 
      error =>{

          this.$vs.dialog({
            type:'alert',
            color: 'danger',
            title: this.$t('COM.ERROR.TITEL'),
            acceptText: this.$t('COM.OK'),
            text: error
          });
          if(this.IsNotNull(old))
          {
            this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: old } });
          } 
      });
    },
    LoadOldData(old)
    {
      if(this.IsNotNull(old))
      {
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: old } });
      }
    },
    LoadAttachments(){
      this.$store.dispatch('debitorobject/getDebitorObjectAttachments',this.selectedObjectId);
    },
    LoadRooms(){
      this.$store.dispatch('debitorobject/getDebitorObjectRooms',this.selectedObjectId);
    },
    RefreshButtonsForRooms(){
      this.$refs.BtnListRooms.RefreshButtons(this.GetButtonsForRooms());
    },
    GetCurrencyString(amount,language_fk,currency_kuerzel){
      return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
    },
    GetButtonsForRooms(){
      return [{label:this.$t('DEBITORCONTACT.NEW_ROOM'),color:'primary',type:'filled',icon:'add',show:true, disabled:false,data:[this.selectedObjectId]},
              {label:this.$t('DEBITORCONTACT.EDIT_ROOM'),color:'primary',type:'filled',icon:'edit',show:true,disabled:this.rooms.length == 0,data:[]},
              {label:this.$t('DEBITORCONTACT.DELETE_ROOM'),color:'primary',type:'filled',icon:'delete',show:true,disabled:this.rooms.length == 0,data:[]}
              ];
    },
    ButtonListClickedRooms(index, data){
      switch(index)
      {
        case 0:
          this.$refs.addDebitorObjectRoomDlg.ShowDlg(data[0]);
          break;
        case 1:
          this.EditRoom();
          break;     
        case 2:
          this.DeleteRoom();
          break;                                       
      }
      
    },    
    ButtonListClicked(index, data){
      switch(index)
      {
        case 0:
          this.$refs.addOfferDlg.ShowDlg(data[0],data[1],data[2]);
          break;
        case 1:
          this.$refs.addPaymentDlg.ShowDlg();
          break;                                  
      }
      
    },
    StartUpload(){
      this.$refs.attachDrop.processQueue();
    },
    ShowAddAttachmentDlg(){
      this.dlgActiveAttachment = true;
    },
    closedAttachment(){
      this.ClearFormAttachment();
    },
    acceptedAttachment(){

        this.ClearFormAttachment();
    },
    canceldAttachment(){
      this.ClearFormAttachment();
    },
    dropzoneQueueComplete(){
      this.LoadAttachments();
      this.ClearFormAttachment();
    },
    dropzoneFileAdded(file){
      var qu = 
      {
        filename:file.upload.filename,
        uuid:file.upload.uuid,
        accepted:file.upload.accepted,
        description:""
      }
      this.queuedFiles.push(qu);
    },
        // eslint-disable-next-line no-unused-vars
    dropzoneSuccess(file, response){
      this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.SUCCESS.UPLOAD_IMG').replace("*1*",file.upload.filename)});
      this.$refs.attachDrop.removeFile(file);
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneError(file, message, xhr){
      this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ERROR.UPLOAD_IMG').replace("*1*",file.upload.filename).replace("*2*",message)});
      this.$refs.attachDrop.removeFile(file);
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneSending(file, xhr, formData) {
      formData.append('object_fk', this.selectedObjectId);
      var description = "";

      for(var i = 0; i < this.queuedFiles.length; i++)
      {
        if(file.upload.uuid == this.queuedFiles[i].uuid)
        {
          description = this.queuedFiles[i].description;
          break;
        }
      }

      formData.append('description',description);
    },

    ClearFormAttachment(){ 

      this.$refs.attachDrop.removeAllFiles();
      this.queuedFiles = [];
    },
    BackBtnClicked(){
      // Dahin zurück wo wir her gekommen sind
      var name = "Dashboard";
      var params = {};
      if(this.IsNotNull(this.prevRoute))
      {
        if(this.prevRoute.name != "Offer" && this.prevRoute.name != "Order")
        {
          name = this.prevRoute.name;
          params = this.prevRoute.params;
        }
      }
      this.$router.push({ name: name, params: params });
    },
    PrevBtnClicked(){
      if(this.selectedContactPrevId != -1)
      {
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: this.selectedContactPrevId } });
      }
    },
    NextBtnClicked(){
      if(this.selectedContactNextId != -1)
      {
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: this.selectedContactNextId } });
      }
    },
    RoomAccepted(){
      this.LoadRooms();
    },
    MemberAccepted(){
      this.LoadData(this.debitorcontact_fk);
    },
    AddDebitorObjectAccepted(){
      this.LoadData(this.debitorcontact_fk);
    },
    AddDebitorAccepted(){
      this.LoadData(this.debitorcontact_fk);
    },
    showEventDescription(descr){
      this.actEventDescr = descr;
      this.popupEventDescrActive = true;
    },
    EditRoom(){
      var room = {};
      if(this.rooms.length > 1)
      {
        room = this.rooms[this.activeRoom];
      }
      else if(this.rooms.length == 1)
      {
        room = this.rooms[0];
      }
      this.$refs.addDebitorObjectRoomDlg.InitDlgData(room);
      // Ruft beim Schließen des Dialogs automatisch LoadRooms auf
    },
    DeleteRoom(){
      var id = 0;
      var name = "";
      if(this.rooms.length > 1)
      {
        id = this.rooms[this.activeRoom].id;
        name =  this.rooms[this.activeRoom].name;
      }
      else if(this.rooms.length == 1)
      {
        id = this.rooms[0].id;
        name =  this.rooms[0].name;
      }
      this.OpenConfirmDeleteRoom(id,name);
    },
    OpenConfirmDeleteRoom(id,name) {
      this.$vs.dialog({
      type: "confirm",
      color: "primary",
      title: this.$t('COM.SURE.TITEL'),
      acceptText: this.$t('COM.DELETE'),
      cancelText:this.$t('COM.CANCEL'),
      text: this.$t('DEBITORCONTACT.ROOM.QUESTION.DELETE').replace("*1*",name),
      accept: this.SendDeleteRoomRequest,
      parameters:{"id": id,"name": name}
      });

    },
    SendDeleteRoomRequest: function(parameters)
    {
      this.$store.dispatch('debitorobject/deleteDebitorObjectRoom', parameters.id)
      .then(response => {
        if(response.success === true)
        {
          this.LoadRooms();
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.ROOM.SUCCESS.DELETE').replace("*1*",parameters.name)});  
        }
        else
        {
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DEBITORCONTACT.ROOM.WARNING.DELETE').replace("*1*",parameters.name)}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ROOM.ERROR.DELETE').replace("*1*",error)});  
      });
    },
    OpenConfirmDeleteEvent(id,name) {
      this.$vs.dialog({
      type: "confirm",
      color: "primary",
      title: this.$t('COM.SURE.TITEL'),
      acceptText: this.$t('COM.DELETE'),
      cancelText:this.$t('COM.CANCEL'),
      text: this.$t('DEBITORCONTACT.HISTORY.QUESTION.DELETE').replace("*1*",name),
      accept: this.SendDeleteEventRequest,
      parameters:{"id": id,"name": name}
      });

    },
    SendDeleteEventRequest: function(parameters)
    {
      this.$store.dispatch('debitorcontact/deleteDebitorContactMember', parameters.id)
      .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.HISTORY.SUCCESS.DELETE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('DEBITORCONTACT.HISTORY.WARNING.DELETE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.MemberAccepted();
          }, 
          error =>{
              var text = this.$t('DEBITORCONTACT.HISTORY.ERROR.DELETE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.MemberAccepted();
          });
    },

    ShowOrder(data)
    {
      // Zum Auftrag navigieren
      this.$router.push({ name: 'Order', params: { order_fk: data.id } });
    },

    ShowOffer(data,editable){

      this.$store.dispatch('accounting/isOfferLocked', data.id)
      .then(resp => {
            
            var params = {id:data.id,locked:true,force:false};
            if(resp.isLocked == true)
            {
              if(this.$can('overwrite', 'admin'))
              {
                params.force = true;
              }
            }
            if(resp.isLocked == false || (resp.isLocked == true && params.force == true))
            {
              if(editable == true)
              {
                this.$store.dispatch('accounting/lockOffer', params)
                .then(response => {
                  if(response.success == true)
                  {
                    // zum angebot navigieren
                    this.$router.push({ name: 'Offer', params: { offer_fk: data.id, editable:editable, for_partnerorder_fk:0 } });
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':response.text}); 
                  }
                },
                error => {
                  var text = this.$t('OFFERS.LOCK_OFFER.ERROR').replace("*1*",error);
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
                });
              }
              else
              {
                // zum angebot navigieren
                this.$router.push({ name: 'Offer', params: { offer_fk: data.id, editable:editable, for_partnerorder_fk:0 } });
              }
            }
            else
            {
              var text = this.$t('OFFERS.OFFER_ALREADY_LOCKED').replace("{0}",resp.username);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('OFFERS.OFFER_LOCKED.ERROR').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
    },
    ShowOfferById(id){
      var dat = {debitor_fk: this.selectedContact.debitor.debitor.id, debitorobj_fk:this.selectedContact.debitorobject.debitorobject.id};
      this.$store.dispatch('accounting/getOffers',dat )
      .then(response => {
        if(this.IsNotNull(response))
        {
           if(this.$store.state.accounting.offers.data != null)
            {
              var data = this.$store.state.accounting.offers.data;
              var found = false;
              for(var i =0; i < data.length;i++)
              {
                if(data[i].id == id)
                {
                  this.ShowOffer(data[i],this.isEditOfferAllowed(data[i].state));
                  found = true;
                  break;
                }
              }
              if(found == false)
              {
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.OFFER_NOT_FOUND')}); 
              }
            }
        }
      }, 
      error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ERROR.REFRESH') + ' ' +error});  
      });
     
    },    
    OfferAdded(id){
      // Es wurde ein neus Angebot eingefügt, Ereignisse neu laden
      if(this.IsNotNull(this.selectedContact))
      {
        this.$store.dispatch('debitorcontact/getDebitorContactMember',this.selectedContact.debitorcontact.id);
      }

      this.ShowOfferById(id);
    },
    PaymentAdded(){

    },
    ViewAttachmentPdf(attId){
      
      this.$store.dispatch('debitorobject/downloadPdfAttachment', attId)
          .then(response => {
              this.$refs.pdfViewer.LoadPdfBlobToViewer(response);
              this.pdfPopupActive = true;
            }
          );

    },
    CheckCreatePdf(){
      if(this.numberOfAttachments > 0)
      {
        this.showCheckboxPrompt = true;
      }
      else
      {
        this.CreatePdf();
      }
    },
    CreatePdf(){
      var name = this.selectedDebitorName + "" + this.selectedObjectName;
      this.$store.dispatch('document/createDocument', {id:this.debitorcontact_fk,name:name, type: documentType.CONTACT,printInternal:this.printInternal,printAttachment:this.printAttachments})
        .then(success => {
          if(success === true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORCONTACT.SUCCESS.PRINT').replace("*1*",name)});
          }
          else
          {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ERROR.PRINT').replace("*1*",name)});
          }
        }, 
        error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORCONTACT.ERROR.PRINT').replace("*1*",name)+' '+error});
        }); 
    }
  }
};
</script>

<style>
div#promptAddObjectAttachmentDlg > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddObjectAttachmentDlg > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}

</style>