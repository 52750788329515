<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptInfoworxInvoiceDetailDlg"
      :is-valid="formValid"
      :buttons-hidden="true">
       <div>
            <div id="debitorcontacts_info_invoice_mem" class="vs-con-loading__container">

              <vs-table
                search
                :data="data"
                :noDataText="$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.NO_DATA')"
                stripe
                max-items="10" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="position">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL1')}}
                  </vs-th> 
                  <vs-th sort-key="artnr">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="bezeichnung">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="technische_Daten">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL4')}}
                  </vs-th> 
                  <vs-th sort-key="menge">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL5')}}
                  </vs-th>
                  <vs-th sort-key="einzelpreis">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL6')}}
                  </vs-th> 
                  <vs-th sort-key="zwischensumme">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL7')}}
                  </vs-th> 
                  <vs-th sort-key="rabatt">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL8')}}
                  </vs-th>
                  <vs-th sort-key="rabattProzent">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL9')}}
                  </vs-th> 
                  <vs-th sort-key="freierText">
                    {{$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TABLE.COL10')}}
                  </vs-th>                                                                                                                                                                                                                                                                                         
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td :data="data[indextr].position">
                      {{data[indextr].position}}
                    </vs-td>                  
                    <vs-td :data="data[indextr].artNr">
                      {{data[indextr].artNr}}
                    </vs-td>
                    <vs-td :data="data[indextr].bezeichnung">
                      {{data[indextr].bezeichnung}}
                    </vs-td>   
                    <vs-td :data="data[indextr].technische_Daten">
                      {{data[indextr].technische_Daten}}
                    </vs-td>   
                    <vs-td :data="data[indextr].menge">
                      {{data[indextr].menge}}
                    </vs-td> 
                    <vs-td :data="data[indextr].einzelpreis">
                      {{GetCurrencyString(data[indextr].einzelpreis, data[indextr].language_fk,data[indextr].currency_kuerzel)}}
                    </vs-td> 
                    <vs-td :data="data[indextr].zwischensumme">
                      {{GetCurrencyString(data[indextr].zwischensumme, data[indextr].language_fk,data[indextr].currency_kuerzel)}}
                    </vs-td>
                    <vs-td :data="data[indextr].rabatt">
                      {{data[indextr].rabatt}}
                    </vs-td> 
                   <vs-td :data="data[indextr].rabattProzent">
                      {{data[indextr].rabattProzent}}
                    </vs-td>  
                    <vs-td :data="data[indextr].freierText">
                      {{data[indextr].freierText}}
                    </vs-td>                                                                                                                                                                                                                                                                                         
                  </vs-tr>
                </template>
              </vs-table>
              
            </div>  
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "InfoworxInvoiceDetailDlg",
  props:[],
  components:{
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TITLE'),
      data:this.GetEmptyData()
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
        this.popupTitle = this.$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TITLE');       
      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
          this.ClearForm();
          this.popupTitle = this.$t('DEBITORCONTACT.INFOWORX_INVOICE_MEM.TITLE');
          
          this.data = JSON.parse(JSON.stringify(data));

          this.dlgActive=true;
        }
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.data=this.GetEmptyData();
      },
      GetEmptyData(){
        return {
          id:0,
        };
      },
      GetCurrencyString(amount,language_fk,currency_kuerzel){
        return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
      }
  },
  computed:{
    formValid(){
      return true;
    },
  },
  watch:{
  }
};
</script>
<style>
div#promptInfoworxInvoiceDetailDlg > div.vs-dialog{
  max-width: 1200px !important;
}
div#promptInfoworxInvoiceDetailDlg > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>