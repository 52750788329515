<template>
  <div id="invoice_table" class="vs-con-loading__container" >
    
  <vs-table
    search
    :data="invoices"
    :noDataText="$t('INVOICE.NO_DATA')"
    stripe
    max-items="50" 
    pagination
    hoverFlat>

    <template slot="header">
      <h3>
        {{$t('INVOICE.TABLE.HEADER')}}
      </h3>
    </template>
    <template slot="thead">                               
      <vs-th sort-key="bez">
        {{$t('INVOICE.TABLE.COL1')}}
      </vs-th>
      <vs-th sort-key="invoice_nr">
        {{$t('INVOICE.TABLE.COL2')}}
      </vs-th> 
      <vs-th sort-key="type">
        {{$t('INVOICE.TABLE.COL7')}}
      </vs-th>                        
      <vs-th sort-key="last_change">
        {{$t('INVOICE.TABLE.COL4')}}
      </vs-th>  
      <vs-th sort-key="state">
        {{$t('INVOICE.TABLE.COL5')}}
      </vs-th>
      <vs-th sort-key="created_by_str" v-show="!invoiceForOrder">
        {{$t('INVOICE.TABLE.COL6')}}
      </vs-th> 
      <vs-th sort-key="betrag">
        {{$t('INVOICE.TABLE.COL8')}}
      </vs-th>                           
      <vs-th></vs-th>                                                                                                                                             
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
        <vs-td :data="data[indextr].bez">
          {{data[indextr].bez}}
        </vs-td>      
        <vs-td :data="data[indextr].invoice_nr">
          {{data[indextr].invoice_nr}}
        </vs-td>   
        <vs-td :data="data[indextr].type">
            {{GetInvoiceTypeText(data[indextr].type)}}
        </vs-td>                   
        <vs-td :data="data[indextr].last_change">
          {{DateToString(data[indextr].last_change,false)}}
        </vs-td>                   
        <vs-td :data="data[indextr].state">
          <vs-chip :color="GetCommonStateColor(data[indextr].state)">
            {{GetCommonStateText(data[indextr].state)}}</vs-chip>
        </vs-td>  
        <vs-td :data="data[indextr].created_by_str" v-show="!invoiceForOrder">
          {{data[indextr].created_by_str}}
        </vs-td> 
        <vs-td >
          {{GetInvoiceSumOffen(data[indextr])}} / <h4>{{GetInvoiceSum(data[indextr])}}</h4>
        </vs-td>                  
        <vs-td >
          <div class="d-flex">
            <PollingButtonDoc :DocumentType="docType" :InitDocId="data[indextr].document_fk" :LinkedFk="data[indextr].id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" ref="InvoicePollingBtn" v-on:clicked="DownloadInvoice"></PollingButtonDoc>
            <SendDebitorDocuemntMailBtn class="mr-1" :doc_nr="data[indextr].invoice_nr" :doc_id="data[indextr].id" :doc_type="docType"></SendDebitorDocuemntMailBtn>
            <ButtonList :buttonArray="[{label:$t('INVOICE.BTN.PRINT'),color:'primary',type:'filled',icon:'print',show:true, disabled:false,data:[data[indextr],indextr]},
                                       {label:$t('INVOICE.BTN.SHOW'),color:'primary',type:'filled',icon:'open_in_new',show:true,disabled:data[indextr].document_fk <= 0,data:[data[indextr].document_fk]},
                                       {label:$t('INVOICE.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show: $can('delete', 'invoice'),disabled:isDisabled,data:[data[indextr].id,data[indextr].invoice_nr]},
                                       {label:$t('INVOICE.BTN.STORNO'),color:'primary',type:'filled',icon:'clear',show:true,disabled:isDisabled,data:[data[indextr].id,data[indextr].invoice_nr]},
                                       {label:$t('INVOICE.BTN.PAYMENT'),color:'primary',type:'filled',icon:'euro',show:true,disabled:paymentNotAllowed(data[indextr]),data:[data[indextr]]}
                                      ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
            
      
          </div>
        </vs-td>                    
        <vs-td v-show="invoiceForOrder">
          <vs-button :disabled="isDisabled" v-show="data[indextr].isForeign" @click="RemoveInvoiceFromOrder(data[indextr])" class="" size="small" color="primary" type="filled" icon="remove"></vs-button>  
        </vs-td>                                                                                                                                                                           
      </vs-tr>
    </template>
  </vs-table>
  <AddPaymentDlg ref="addPaymentDlg" v-on:Success="PaymentAdded"></AddPaymentDlg>
  <vs-popup fullscreen :title="$t('INVOICE.POPUP_INVOICE')" :active.sync="pdfPopupActive">
      <PdfJsViewer ref="pdfViewer" fileName=""></PdfJsViewer>
  </vs-popup>
</div> 
</template>
<script>
import enumHelper from '../../../helper/enumHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import helperMethods from '../../../helper/staticFuncHelper';
import downloadMethods from '../../../helper/staticDownloadFuncHelper';
import {documentType} from "../../../helper/enumHelper";
import PollingButtonDoc from "../PollingButtonDoc.vue";
import AddPaymentDlg from "../dialogs/AddPaymentDlg.vue";
import {commonStates} from '../../../helper/enumHelper';
import PdfJsViewer from '../PdfJsViewer.vue';
import {invoiceTypes}  from '../../../helper/enumHelper';
import ButtonList from "../ButtonList.vue";
import SendDebitorDocuemntMailBtn from "../dialogs/SendDebitorDocuemntMailBtn.vue";

export default {
  name: "InvoiceTable",
  props:['value','InvoiceForOrder','isDisabled'],
  components:
  {
    PollingButtonDoc,
    AddPaymentDlg,
    // eslint-disable-next-line vue/no-unused-components
    PdfJsViewer,
    ButtonList,
    SendDebitorDocuemntMailBtn
  },
   data: function (){
     return {
        invoices:[],
        docType:documentType.INVOICE,
        idToShow:this.value,
        invoiceForOrder:this.InvoiceForOrder,
        pdfPopupActive:false
     }
  },
  created(){
    this.RefreshData();
  },
  computed:{
    getInvoiceTableData() {
      
      var data = [];
      if(this.invoiceForOrder == false)
      {
        if(this.$store.state.accounting.invoices.data != null)
        {
          data = this.$store.state.accounting.invoices.data;
        }
      }
      else
      {
        if(this.$store.state.accounting.invoicesForOrder.data != null)
        {
          data = this.$store.state.accounting.invoicesForOrder.data;
        }
      }
      return data;
    },
    loadingInvoiceTableData () {
      var loading = false;
      if(this.invoiceForOrder == false)
      {
        if(typeof(this.$store.state.accounting.invoices.status) != 'undefined')
          loading = this.$store.state.accounting.invoices.status.loading;
      }
      else
      {
        if(typeof(this.$store.state.accounting.invoicesForOrder.status) != 'undefined')
          loading = this.$store.state.accounting.invoicesForOrder.status.loading;
      }
      return loading;
    }
  },
  methods:{ 
    ...codeHelper,
    ...helperMethods,
    ...enumHelper,
    ...downloadMethods,
    RefreshData(){
      if(this.invoiceForOrder == false)
      {
        var dat = {debitorcontact_fk: this.idToShow};
        this.$store.dispatch('accounting/getInvoices',dat ); 
      }
      else
      {
        this.$store.dispatch('accounting/getInvoicesForOrder',this.idToShow ); 
      }
    },
    ButtonListClicked(index, data){
      switch(index)
      {
        case 0:
          this.PrintInvoice(data[0])
          break;
        case 1:
          this.ViewInvoicePdf(data[0])
          break;
        case 2:
          this.OpenConfirmDelete(data[0],data[1])
          break;  
        case 3:
          this.OpenConfirmStorno(data[0],data[1]);
          break;  
        case 4:
          this.CreatePayment(data[0]);
          break;                                    
      }
      
    },
    PrintInvoice(data)
    {
        var type = documentType.INVOICE;
      
        if(data.type == invoiceTypes.STORNO)
        {
          type = documentType.STORNO;
        }
        if(data.type == invoiceTypes.GUTSCHRIFT)
        {
          type = documentType.GUTSCHRIFT;
        }
        var name = data.bez + ' (' + data.invoice_nr + ')';
            this.$store.dispatch('document/createDocument', {id:data.id,name:data.invoice_nr, type: type})
              .then(success => {
                if(success === true)
                {
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.SUCCESS.PRINT').replace("*1*",name)});
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)});
                }

              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.PRINT').replace("*1*",name)+' '+error});
              }); 
    },
    DownloadInvoice(actDocId)
    {
      if(this.IsNotNull(actDocId))
      {
          this.$store.dispatch('document/downloadDocument', actDocId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
      }
    },
    ViewInvoicePdf(actDocId){
      if(this.IsNotNull(actDocId))
      {
        this.$store.dispatch('document/downloadDocument', actDocId)
            .then(response => {
                this.$refs.pdfViewer.LoadPdfBlobToViewer(response);
                this.pdfPopupActive = true;
              }
            );
      }
    },
    GetInvoiceSum(invoice)
    {
      var sum = 0;
      var txt = "";
      if(this.IsNotNull(invoice))
      {
        sum = invoice.amount;
        txt = this.GetCurrencyString(sum,invoice.language_fk,invoice.currency_kuerzel);
      }
      return txt;
    },
    GetInvoiceSumOffen(invoice)
    {
      var txt = "";
      if(this.IsNotNull(invoice))
      {
        txt = this.GetCurrencyString(invoice.amount_bez,invoice.language_fk,invoice.currency_kuerzel);
      }
      return txt;
    },
    GetCurrencyString(amount,language_fk,currency_kuerzel){
        return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
      },
    CreatePayment(invoice){
        if(this.IsNotNull(invoice))
        {
          var data = {amount:invoice.amount-invoice.amount_bez,reference:invoice.invoice_nr};
          this.$refs.addPaymentDlg.ShowDlg(data);
        }
    },
    PaymentAdded(){
      this.$emit('PaymentAdded');
    },
    paymentNotAllowed(payment){
      var retVal = true;
      if(this.IsNotNull(payment))
      {
        if(payment.state == commonStates.OPEN || payment.state == commonStates.INWORK  || payment.state == commonStates.CREATED)
        {
          retVal = false;
        }
      }
      return retVal && this.isDisabled;
    },
    RemoveInvoiceFromOrder(invoice){

        var data = {
          order_fk:this.idToShow,
          invoice_fk:invoice.id,
          remove:true
        };

        var name = this.order_nr;
        this.$store.dispatch('accounting/addRemoveInvoiceToOrder', data)
        .then(response => {
          if(response.success === true)
          {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ORDER.ADD_INVOICE.SUCCESS.SAVE').replace("*1*",name)});  
              this.RefreshData();
              this.$emit('DataChanged');
          }
          else
          {
            var text = this.$t('ORDER.ADD_INVOICE.WARNING.SAVE').replace("*1*",name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
          
        }, 
        error =>{
            var text = this.$t('ORDER.ADD_INVOICE.ERROR.SAVE').replace("*1*",name);
            text = text.replace("*2*",error);
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
        });

        this.ClearForm();
    },
    OpenConfirmDelete(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('INVOICE.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('accounting/deleteInvoice', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.RefreshData(); 
            this.$emit('DataChanged');
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.SUCCESS.DELETE').replace("*1*",parameters.bez)}); 
          }
          else
          {
            var text = this.$t('INVOICE.WARNING.DELETE').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      OpenConfirmStorno(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.STORNO'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('INVOICE.QUESTION.STORNO').replace("*1*",bez),
          accept: this.SendStornoRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendStornoRequest: function(parameters)
      {
        var data = {id:parameters.id};
        this.$store.dispatch('accounting/stornoInvoice', data)
        .then(response => {
          if(response.success === true)
          {
            this.RefreshData(); 
            this.$emit('DataChanged');
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('INVOICE.SUCCESS.STORNO').replace("*1*",parameters.bez)}); 
          }
          else
          {
            var text = this.$t('INVOICE.WARNING.STORNO').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('INVOICE.ERROR.STORNO').replace("*1*",parameters.bez) + ' ' +error});  
        });
      }
  },
  watch:{
    getInvoiceTableData(value)
    {
      this.invoices = value;
    },
    loadingInvoiceTableData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#invoice_table'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#invoice_table'});
      }
    },
    value(val){
            this.idToShow =  val;
            this.RefreshData();
        },
  }
};
</script>
<style >
.vs-popup--content{
height:99% !important;
}
</style>
